<template>
  <router-view/>
</template>

<script>
export default {
  created() {
    this.setVH();
    window.addEventListener('resize', () => this.setVH());
  },
  methods: {
    setVH() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${ vh }px`);
    }
  },
}
</script>
