<template>
  <div>
    <div class="absolute top-0 left-0 w-full h-full bg-white opacity-80 z-10"></div>
    <div
      class="modal absolute top-0 right-0 h-full w-full lg:w-4/5 rounded-tl-2xl rounded-bl-2xl bg-white bg-opacity-100 shadow-lg flex flex-col z-10 overflow-hidden"
      :class="{'w-full': full}"
    >
      <img
        src="@/assets/images/pattern-up.png" alt="background pattern"
        class="absolute top-0 left-0 hidden md:block" style="width: 300px"
      />
      <img
        src="@/assets/images/pattern-down.png" alt="background pattern"
        class="absolute bottom-0 right-0 z-auto hidden mb:block" style="width: 300px;"
      />
      <div class="flex flex-col h-full z-50 overflow-y-auto p-6 lg:p-10">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    full: {
      type: Boolean,
      default: false
    }
  }
}
</script>
