<template>
  <div class="px-6 lg:px-12 pb-8">
    <SectionHeader
      title="Provide Next of Kin"
      subtitle="Please fill in all fields correctly"
    />
    <form>
      <ValidationObserver ref="observer">
        <div class="flex flex-col space-y-4">
          <ValidationProvider name="Full name" rules="required" v-slot="{ errors }">
            <label class="input-label-color flex mb-2 text-gray-600">Full name</label>
            <AppInput v-model="values.full_name" type="text" class="w-full" :errors="errors"/>
          </ValidationProvider>
          <ValidationProvider name="Email address" rules="required" v-slot="{ errors }">
            <label class="input-label-color flex mb-2 text-gray-600">Email address</label>
            <AppInput v-model="values.email" type="email" class="w-full" :errors="errors"/>
          </ValidationProvider>
          <ValidationProvider name="Phone number" rules="required|phone" v-slot="{ errors }">
            <label class="input-label-color flex mb-2 text-gray-600">Phone number</label>
            <AppInput v-model="values.phone" type="text" class="w-full" :errors="errors"/>
          </ValidationProvider>
          <ValidationProvider name="address" rules="required" v-slot="{ errors }">
            <AppTextArea placeholder="Home/Street address" v-model="values.address" :errors="errors"/>
          </ValidationProvider>
          <ValidationProvider name="Relationship" rules="required" v-slot="{ errors }">
            <label class="input-label-color flex mb-2 text-gray-600">Relationship</label>
            <AppSelect v-model="values.relationship" name="relationship" label="Relationship" :errors="errors">
              <option value="spouse">Spouse</option>
              <option value="sibling">Sibling</option>
              <option value="child">Child</option>
              <option value="relative">Relative</option>
              <option value="friend">Friend</option>
            </AppSelect>
          </ValidationProvider>
        </div>
        <div class="flex mt-10">
          <AppButton @click="submit()" :loading="loading">Next</AppButton>
        </div>
      </ValidationObserver>
    </form>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppSelect from "@/components/global/Select";
import AppInput from "@/components/global/Input";
import AppButton from "@/components/global/Button";
import AppTextArea from "@/components/global/TextArea.vue";

export default {
  name: "NextOfKin",
  components: { AppTextArea, AppButton, AppInput, AppSelect, SectionHeader },
  data() {
    return {
      loading: false,
      values: {
        full_name: '',
        email: '',
        phone: '',
        address: '',
        relationship: '',
      }
    }
  },
  created() {
    const { next_of_kin, config } = this.$store.state;
    if (!config.show_nok) return this.$emit('skip');
    this.values = { ...next_of_kin };
  },
  methods: {
    async submit() {
      if (!(await this.$refs.observer.validate())) return;
      this.$store.commit("patch", { next_of_kin: { ...this.values } });
      this.$emit("done");
    }
  }
}
</script>
