<template>
  <div>
    <div class="flex bg-gray-100 space-x-1 p-1 rounded-lg">
      <button
        @click="tab = 'internet'"
        class="rounded-md px-2 py-1 flex-1 transition duration-300 opacity-70 text-sm"
        :class="{ 'bg-white shadow font-medium opacity-100': tab === 'internet'}"
      >
        Internet banking
      </button>
      <button
        @click="tab = 'mobile'"
        class="rounded-md px-2 py-1 flex-1 transition duration-300 opacity-70 text-sm"
        :class="{ 'bg-white shadow font-medium opacity-100': tab === 'mobile'}"
      >
        Mobile banking
      </button>
    </div>
    <div v-if="tab === 'internet'" class="mt-6">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <div class="rounded-lg p-6 cursor-pointer min-h-72 border bg-gray-100 border-gray-200 flex flex-col">
            <div>
              <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
                1
              </div>
            </div>
            <ul class="list-disc list-inside text-sm mt-8">
              <li>Log on to internet banking</li>
              <li>Click “Service Requests”</li>
              <li>Click “MyBankStatement”</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="rounded-lg p-6 cursor-pointer min-h-72 border bg-gray-100 border-gray-200 flex flex-col">
            <div>
              <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
                2
              </div>
            </div>
            <ul class="list-disc list-inside text-sm mt-8">
              <li>Select “From Account” (Account to debit) and “Statement Account”</li>
              <li>Select “Start” & “End” dates</li>
              <li>Select “Destination” e.g. Ren Money</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="rounded-lg p-6 cursor-pointer min-h-72 border bg-gray-100 border-gray-200 flex flex-col">
            <div>
              <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
                3
              </div>
            </div>
            <ul class="list-disc list-inside text-sm mt-8">
              <li>Click “Make Statement Request” to retrieve payment information</li>
              <li>Click the “Continue” button to send generated statement to your preferred third party.</li>
            </ul>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div v-if="tab === 'mobile'" class="mt-6">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <div class="rounded-lg p-6 cursor-pointer min-h-72 border bg-gray-100 border-gray-200 flex flex-col">
            <div>
              <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
                1
              </div>
            </div>
            <ul class="list-disc list-inside text-sm mt-8">
              <li>Download the Zenith mobile on your “Playstore” for Android phones and “Applestore” for IPhones</li>
              <li>Log on to Mobile Banking with your User ID and Password</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="rounded-lg p-6 cursor-pointer min-h-72 border bg-gray-100 border-gray-200 flex flex-col">
            <div>
              <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
                2
              </div>
            </div>
            <ul class="list-disc list-inside text-sm mt-8">
              <li>Click the Menu options button to reveal the menu</li>
              <li>Click the “Bank Services” module</li>
              <li>Click the “MyBankStatement” option</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="rounded-lg p-6 cursor-pointer min-h-72 border bg-gray-100 border-gray-200 flex flex-col">
            <div>
              <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
                3
              </div>
            </div>
            <ul class="list-disc list-inside text-sm mt-8">
              <li>Select “Statement Account” and “From Account”</li>
              <li>Select “Start” & “End” dates</li>
              <li>Select "Creditclan" from the destination list</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="rounded-lg p-6 cursor-pointer min-h-72 border bg-gray-100 border-gray-200 flex flex-col">
            <div>
              <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
                4
              </div>
            </div>
            <ul class="list-disc list-inside text-sm mt-8">
              <li>Click the “Validate” button</li>
              <li>Click “Send Statement” to send generated statement to your preferred third party.</li>
            </ul>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZenithInstructions",
  data: () => ({
    tab: 'mobile',
    swiperOptions: {
      slidesPerView: 1.5,
      spaceBetween: 30,
    }
  })
}
</script>
