<template>
  <div class="h-full flex flex-col px-6 lg:px-12 pb-8">
    <Loader v-if="loading" :text="loading" class="my-auto"/>
    <div v-else class="my-auto">
      <SectionHeader
        title="We will like to set a mandate on this account"
        subtitle="Kindly upload a copy of signature attached to this account"
      />
      <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
      <div class="border bg-gray-50 rounded p-4">
        <div class="text-gray-700 text-sm mb-1">{{ bank.name }}</div>
        <div class="text-gray-400">
          Account number: <span class="font-semibold text-gray-700">{{ account.account_number }}</span>
        </div>
        <div class="text-gray-400">
          Account name: <span class="font-semibold text-gray-700">{{ account.account_name }}</span>
        </div>
      </div>
      <label
        class="border-2 border-dashed rounded-xl w-full flex cursor-pointer overflow-hidden px-5 py-4 relative mt-4 hover:bg-gray-50"
        :class="{'p-0 w-48 h-36': preview}"
      >
        <i class="flex items-center justify-center">
          <i class="fa fa-upload text-yellow-400 mr-3"></i>Upload signature
        </i>
        <input
          type="file" name="file" id="file" hidden @change="handleFileChange"
          accept="image/png,image/jpeg,image/jpg"
        />
      </label>
      <button class="text-gray-600 mt-8" type="button" @click="modal = 'help'">
        How to upload signature
      </button>
    </div>

    <Modal v-if="cropper.visible" full>
      <div class="my-auto">
        <cropper
          class="h-72"
          :src="cropper.image"
          :stencil-props="{ aspectRatio: 16 / 9 }"
          @change="change"
        />
        <div class="flex justify-center mt-10">
          <AppButton :loading="uploading" @click="upload()" :disabled="uploading">Upload</AppButton>
          <button
            @click="reset()"
            class="py-1 px-3 border border-red-500 text-red-500 rounded-lg ml-2"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>

    <Modal v-if="modal === 'help'" @close="modal = ''">
      <div class="flex items-center justify-between mb-6">
        <h5 class="text-xl font-semibold text-gray-600">How to upload signature</h5>
        <button
          class="w-8 h-8 rounded-full flex justify-center items-center border border-red-500 text-red-500 hover:bg-red-50"
          @click="modal = ''"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
      <ol class="list-decimal list-inside leading-8">
        <li>Get a plain white sheet of paper</li>
        <li>Sign your signature on it</li>
        <li>Take a picture of the signature</li>
        <li>Upload picture here and continue</li>
      </ol>
      <Message variant="info" class="mt-8">This is used to help us fill the mandate form</Message>
    </Modal>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppButton from "@/components/global/Button";
import Loader from "@/components/global/Loader";
import ajax from "@/mixins/ajax";
import Modal from "@/components/global/Modal.vue";
import { Cropper } from 'vue-advanced-cropper';
import Message from "@/components/global/Message.vue";

export default {
  name: "Signature",
  components: { Message, Modal, Loader, AppButton, SectionHeader, Cropper },
  mixins: [ajax],
  data() {
    return {
      file: null,
      preview: null,
      account: this.$store.state.account,
      banks: this.$store.state.banks_mono,
      cropper: {
        visible: false,
        image: null,
        canvas: null
      },
      uploading: false,
      modal: '',
    }
  },
  created() {
    const { account, stage, config: { show_signature }, payment } = this.$store.state;
    if (!show_signature || !account.bank_id) return this.$emit('done');
    if (payment && !payment.upfront) return this.$emit('done');
    this.getBanks();
    if (stage !== 'signature') this.$store.dispatch('updateStage', { stage: 'signature' });
  },
  methods: {
    async handleFileChange(e) {
      this.file = e.target.files[0];
      this.cropper.image = await this.convertToBase64(this.file);
      this.cropper.visible = true;
    },
    change({ canvas }) {
      this.cropper.canvas = canvas
    },
    reset() {
      this.cropper.visible = false;
      this.cropper.image = null;
      this.cropper.canvas = null;
    },
    upload() {
      const url = this.cropper.canvas.toDataURL();
      if (url) this.submit(url);
      this.cropper.visible = false;
    },
    async submit(signature) {
      if (!signature) return;
      this.$mixpanel.track('Attempted to setup mandate', {
        platform: this.$store.state.config.platform,
        request_id: this.$store.state.request_id,
      });
      try {
        this.setLoading('Setting up mandate..');
        const { token, request_id } = this.$store.state;
        const { data } = await this.$http.post('/customer/cmms/mandate', {
          token, request_id, signature,
        });
        if (data.status) this.next();
        this.setLoading('');
      } catch (e) {
        const message = e?.response?.data?.message || e.message || 'An error occurred, please try again!';
        this.setError(message, true);
        this.$mixpanel.track('Error while setting up mandate', {
          platform: this.$store.state.config.platform,
          request_id: this.$store.state.request_id,
          error: message,
        });
      }
    },
    next() {
      this.$emit('done');
    },
    convertToBase64(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => resolve(reader.result), false);
        if (file) reader.readAsDataURL(file);
      })
    },
    async getBanks() {
      if (this.banks) return;
      const res = await this.$http.get('https://mobile.creditclan.com/webapi/v1/banks_mono');
      this.banks = res.data.data;
    }
  },
  computed: {
    bank() {
      return this.banks?.find(bank => bank.id === this.account.bank_id) || {};
    },
  }
}
</script>
