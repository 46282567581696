<template>
  <div v-if="overlay" class="my-auto w-full">
    <RandomQuestions @done="overlay = ''"/>
  </div>
  <div v-else class="h-full flex flex-col">
    <div class="pt-6 lg:py-10 h-full flex flex-col">
      <div class="flex justify-between mb-8 px-6 lg:px-12">
        <button
          @click="popView()"
          class="w-8 h-8 rounded-full text-gray-500 bg-gray-50 border text-sm flex items-center justify-center"
          :class="{ 'pointer-events-none opacity-0': history.length <= 1 || loading }"
        >
          <i class="fa fa-chevron-left"></i>
        </button>
        <button
          @click="cancel()" v-if="inIframe"
          class="w-8 h-8 rounded-full flex justify-center items-center border border-red-500 text-red-500 hover:bg-red-50"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="h-full flex flex-col md:h-auto md:my-auto">
        <div v-if="loading" class="px-6 lg:px-12 my-auto">
          <Loader :text="loading"/>
        </div>
        <Error v-else-if="error" :text="error" :show-retry="!!retryCb" @retry="retry()"/>
        <template v-else>
          <VerifyEmail
            v-if="view === 'verify-email'"
            @done="pushView('questions-alert')"
            @skip="replaceView('questions-alert')"
          />
          <QuestionsAlert
            v-if="view === 'questions-alert'"
            @done="replaceView('initial-questions')"
            @skip="replaceView('initial-questions')"
          />
          <RandomQuestions
            v-if="view === 'initial-questions'"
            :max="1"
            @done="replaceView('picture')"
            @skip="replaceView('picture')"
          />
          <Picture
            v-if="view === 'picture'"
            @done="pushView('confirm-address')"
            @skip="replaceView('confirm-address')"
          />
          <ConfirmAddress
            v-if="view === 'confirm-address'"
            @done="pushView('income')"
            @skip="replaceView('income')"
          />
          <Income
            v-if="view === 'income'"
            @done="pushView('profile')"
            @skip="replaceView('profile')"
          />
          <Profile
            v-if="view === 'profile'"
            @done="pushView('work')"
            @skip="replaceView('work')"
          />
          <WorkInformation
            v-if="view === 'work'"
            @done="pushView('verify-work-email')"
            @skip="replaceView('verify-work-email')"
          />
          <VerifyWorkEmail
            v-if="view === 'verify-work-email'"
            @done="pushView('work-identity')"
            @skip="replaceView('work-identity')"
          />
          <WorkIdentity
            v-if="view === 'work-identity'"
            @done="pushView('next-of-kin')"
            @skip="replaceView('next-of-kin')"
          />
          <NextOfKin
            v-if="view === 'next-of-kin'"
            @done="pushView('social-handles')"
            @skip="replaceView('social-handles')"
          />
          <SocialHandles
            v-if="view === 'social-handles'"
            @done="pushView('fcn')"
            @skip="replaceView('fcn')"
          />
          <FrequentlyCalledNumbers
            v-if="view === 'fcn'"
            @done="pushView('nin')"
            @skip="replaceView('nin')"
          />
          <NationalIdentityNumber
            v-if="view === 'nin'"
            @view="pushView($event)"
            @done="runFaceMatch(); pushView('account')"
            @skip="runFaceMatch(); replaceView('account')"
          />
          <BankAccount
            v-if="view === 'account'"
            @done="pushView('bank-statement')"
            @skip="replaceView('bank-statement')"
          />
          <BankStatement
            v-if="view === 'bank-statement'"
            @back="popView()"
            @done="finish()"
            @skip="finish()"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Picture from "@/components/sections/Picture.vue";
import Loader from "@/components/global/Loader.vue";
import view from "@/mixins/view.js";
import ajax from "@/mixins/ajax.js";
import Error from "@/components/global/Error.vue";
import RandomQuestions from "@/components/sections/RandomQuestions.vue";
import ConfirmAddress from "@/components/sections/ConfirmAddress.vue";
import WorkInformation from "@/components/sections/WorkInformation.vue";
import SocialHandles from "@/components/sections/SocialHandles.vue";
import FrequentlyCalledNumbers from "@/components/sections/FrequentlyCalledNumbers.vue";
import NationalIdentityNumber from "@/components/sections/NationalIdentityNumber.vue";
import Income from "@/components/sections/Income.vue";
import BankAccount from "@/components/sections/BankAccount.vue";
import BankStatement from "@/components/sections/BankStatement.vue";
import QuestionsAlert from "@/components/sections/QuestionsAlert.vue";
import VerifyEmail from "@/components/sections/VerifyEmail.vue";
import axios from "axios";
import Profile from "@/components/sections/Profile.vue";
import VerifyWorkEmail from "@/components/sections/VerifyWorkEmail.vue";
import NextOfKin from "@/components/sections/NextOfKin.vue";
import WorkIdentity from "@/components/sections/WorkIdentity.vue";

export default {
  name: "PreAnalysis",
  components: {
    WorkIdentity,
    NextOfKin,
    VerifyWorkEmail,
    Profile,
    VerifyEmail,
    QuestionsAlert,
    BankStatement,
    BankAccount,
    Income,
    NationalIdentityNumber,
    FrequentlyCalledNumbers,
    SocialHandles,
    WorkInformation,
    ConfirmAddress,
    RandomQuestions,
    Error,
    Loader,
    Picture,
  },
  mixins: [view, ajax],
  data: () => ({
    inIframe: false
  }),
  created() {
    this.getBanks();
    this.inIframe = window.self !== window.top;
    this.init();
    if (this.$store.getters['lastApplied'].recently) return this.finish();
    this.pushView('verify-email');
    this.$bus.$on('time-finished', () => {
      this.finish();
    });
  },
  methods: {
    async init() {
      let { request_id } = this.$store.state;
      if (!request_id) {
        request_id = await this.createRequest(true);
        if (request_id) {
          await this.saveCreditclanRequestId(request_id, true);
        }
      }
    },
    async runFaceMatch(retries = 1) {
      const { has_data } = this.$store.state;
      if (!has_data) return;
      if (retries >= 3) return;
      try {
        const res = await this.$http.post('/customer/facematch', {
          token: this.$store.state.token
        });
        if (!res?.data?.people_id) return await this.runFaceMatch(++retries);
      } catch (e) {
        console.log(e);
        return await this.runFaceMatch(++retries);
      }
    },
    async finish() {
      this.modal = '';
      let { request_id, bs } = this.$store.state;
      await this.saveUserData();
      if (!request_id) {
        request_id = await this.createRequest();
        if (this.history.length > 1) await this.saveCreditclanRequestId(request_id);
      }
      if (request_id) {
        if (!bs?.response) return this.$emit('done');
        this.setLoading('It takes less 10 seconds..');
        const analyzed = await this.submitBsReport(request_id);
        this.setLoading('');
        if (analyzed) return this.$emit('done');
        if (!this.error) {
          this.$store.commit('patch', { bs: { response: null } });
          this.setError('We could not analyze your bank statement, please try again');
          this.$mixpanel.track('Could not analyze bank statement', {
            platform: this.$store.state.config.platform,
            request_id: this.$store.state.request_id,
          });
          this.setRetry(() => this.setError('', true));
        }
      }
    },
    async createRequest(silent = false) {
      if (!silent) {
        this.overlay = 'random-questions';
        this.setLoading('Creating request..');
      }
      try {
        const {
          request, profile, frequently_called_numbers: fcn, account, work, home_address, social, extra, next_of_kin,
          config: { platform }
        } = this.$store.state;
        let url = '/loan/apply_for_bnpl';
        if (platform === 'rent-tenant') url = '/loan/apply_for_rent';
        const payload = {
          frequently_called_numbers: [...fcn].filter(n => n.phone),
          profile, work, request, next_of_kin, home_address, social, ...extra
        }
        if (account?.account_name) payload.account = account;
        const { data } = await this.$http.post(url, payload);
        if (!data.status) {
          if (!silent) {
            this.setError(data.message || 'An error occurred while creating your request, please try again', true);
            this.setRetry(() => this.finish());
          }
          return false
        }
        this.$store.commit('patch', { request_id: data.dd });
        await this.$store.dispatch('updateStage', { stage: 'created' });
        return data.dd;
      } catch (e) {
        if (!silent) {
          this.setError(e.response ? e.response.data.message : e.message || 'An error occurred, please try again', true);
          this.setRetry(() => this.finish());
        }
        return false;
      }
    },
    async submitBsReport(request_id, prev_bs_token = null, retries = 2) {
      if (retries === 0) return false;
      try {
        const {
          bs: { response }, token,
          account: { account_name, account_number, bank_id }
        } = this.$store.state;
        let bs_token = prev_bs_token;
        if (!bs_token) {
          const { data: { token: new_bs_token } } = await this.$http.post('https://mobile.creditclan.com/api/v3/bankstatement/initiate', {
            request_id, token,
            account: { account_number, bank_id, account_name, },
            has_online_banking: response.has_online_banking ? '1' : '0'
          });
          bs_token = new_bs_token;
        }
        const res = await this.$http.post(`/bankstatement/update`, {
          token: bs_token, analyze: true, ...response
        });
        if (!res.data.status) return await this.submitBsReport(request_id, bs_token, --retries);
        return true;
      } catch (e) {
        const message = e.response ? e.response.data.message : e.message || 'An error occurred, please try again';
        this.setError(message);
        this.setRetry(() => this.finish());
        this.$mixpanel.track('Error while submitting bs report', {
          platform: this.$store.state.config.platform,
          request_id: this.$store.state.request_id,
          error: message
        });
        return false;
      }
    },
    async saveUserData() {
      this.setLoading('Submitting request..', true);
      try {
        const {
          request, profile, frequently_called_numbers: fcn, account, work,
          home_address, social, token, next_of_kin, documents
        } = this.$store.state;
        await this.$http.post('/customer/save_market_data', {
          frequently_called_numbers: [...fcn].filter(n => n.phone),
          profile, account, work, request, home_address, social, token, next_of_kin,
          documents: documents.map(doc => doc.url)
        });
      } catch (e) {
        this.setError(e.response ? e.response.data.message : e.message || 'An error while saving your data, please try again');
        this.setRetry(() => this.saveUserData());
      }
      this.setLoading('');
    },
    cancel() {
      this.$bus.$emit('cancel');
    },
    async saveCreditclanRequestId(request_id, silent) {
      if (!silent) this.setLoading('Please wait..');
      const { config: { platform }, extra } = this.$store.state;
      if (platform === 'shop-global') await this.saveShopGlobalCcId(request_id, extra.global_request_id);
      if (platform === 'shop-power') await this.saveShopMerchantCcId(request_id, extra.power_merchant_request_id);
      if (platform === 'shop-merchant') await this.saveShopMerchantCcId(request_id, extra.merchant_request_id || extra.power_merchant_request_id);
      if (platform === 'rent-tenant') await this.saveRentCcId(request_id, extra.rent_id);
      if (platform === 'school-parent') await this.saveParentCcId(request_id, extra.parent_request_id);
      if (platform === 'school-teacher') await this.saveTeacherCcId(request_id, extra.teacher_request_id);
      if (platform === 'merchant') await this.saveMerchantCcId(request_id, extra.merchant_request_id);
      if (!silent) this.setLoading('');
    },
    async saveShopGlobalCcId(creditclan_request_id, global_request_id) {
      if (!global_request_id) return;
      try {
        await axios.patch('https://sellbackend.creditclan.com/merchantclan/public/index.php/api/global/request', {
          creditclan_request_id, global_request_id
        });
      } catch (e) {
        console.log({ e });
      }
    },
    async saveShopMerchantCcId(creditclan_request_id, merchant_request_id) {
      if (!merchant_request_id) return;
      try {
        await axios.patch(`https://sellbackend.creditclan.com/merchantclan/public/index.php/api/request/${ merchant_request_id }`, {
          creditclan_request_id
        });
      } catch (e) {
        console.log({ e });
      }
    },
    async saveParentCcId(creditclan_request_id, parent_request_id) {
      if (!parent_request_id) return;
      const { profile_image } = this.$store.state.profile;
      try {
        await axios.post(`https://sellbackend.creditclan.com/parent/index.php/external/update_cc_request`, {
          creditclan_request_id, parent_request_id, profile_image
        });
      } catch (e) {
        console.log({ e });
      }
    },
    async saveTeacherCcId(creditclan_request_id, teacher_request_id) {
      if (!teacher_request_id) return;
      try {
        await axios.post(`https://sellbackend.creditclan.com/parent/index.php/external/update_cc_request`, {
          creditclan_request_id, teacher_request_id
        });
      } catch (e) {
        console.log({ e });
      }
    },
    async saveMerchantCcId(creditclan_request_id, merchant_request_id) {
      if (!merchant_request_id) return;
      try {
        await axios.post(`https://sellbackend.creditclan.com/parent/index.php/external/update_cc_request`, {
          creditclan_request_id, merchant_request_id
        });
      } catch (e) {
        console.log({ e });
      }
    },
    async saveRentCcId(creditclan_request_id, rent_id) {
      if (!rent_id) return;
      try {
        await axios.post(`https://wema.creditclan.com/api/v3/rent/update_cc_request`, {
          rent_id, creditclan_request_id
        });
      } catch (e) {
        console.log({ e });
      }
    },
    async getBanks() {
      if (this.$store.state.banks_mono) return;
      const res = await this.$http.get('https://mobile.creditclan.com/webapi/v1/banks_mono');
      const banks_mono = res.data.data;
      this.$store.commit('patch', { banks_mono })
    }
  }
};
</script>
