<template>
  <div class="h-full flex flex-col">
    <div class="px-6 lg:px-12 pb-8">
      <SectionHeader title="Select your monthly income range"/>
      <div class="grid grid-cols-2 gap-4">
        <div
          @click="setMonthlyIncome(25000)"
          class="px-3 py-2 text-base border rounded-lg cursor-pointer flex items-center justify-between"
        >
          0 - 50k
          <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
        </div>
        <div
          @click="setMonthlyIncome(50000)"
          class="px-3 py-2 text-base border rounded-lg cursor-pointer flex items-center justify-between"
        >
          50k - 100k
          <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
        </div>
        <div
          @click="setMonthlyIncome(100000)"
          class="px-3 py-2 text-base border rounded-lg cursor-pointer flex items-center justify-between"
        >
          100k - 250k
          <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
        </div>
        <div
          @click="setMonthlyIncome(250000)"
          class="px-3 py-2 text-base border rounded-lg cursor-pointer flex items-center justify-between"
        >
          250k - 500k
          <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
        </div>
        <div
          @click="setMonthlyIncome(500000)"
          class="px-3 py-2 text-base border rounded-lg cursor-pointer flex items-center justify-between"
        >
          500k - 1m
          <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
        </div>
        <div
          @click="setMonthlyIncome(1000000)"
          class="px-3 py-2 text-base border rounded-lg cursor-pointer flex items-center justify-between"
        >
          1m & above
          <i class="fa fa-long-arrow-alt-right text-gray-500"></i>
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-auto md:hidden">
      <img src="../../assets/images/whatsapp-phone-half.png" alt="" class="w-52"/>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";

export default {
  name: "Income",
  components: { SectionHeader },
  created() {
    const { show_income } = this.$store.state.config;
    const { recently, twoWeeks, oneMonth } = this.$store.getters['lastApplied'];
    if (recently || twoWeeks || oneMonth || !show_income) return this.$emit('skip');
  },
  data() {
    return {
      monthly_income: this.$store.state.work.monthly_income
    }
  },
  methods: {
    setMonthlyIncome(monthly_income) {
      this.$store.commit('work', { monthly_income });
      this.next();
    },
    next() {
      this.$emit('done');
    }
  }
}
</script>
