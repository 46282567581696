<template>
  <div v-if="variant === 'skateboard'" class="h-full flex flex-col">
    <p v-if="current" class="py-32 md:py-20 text-center text-xl font-semibold my-auto">{{ current }}</p>
    <div class="flex justify-center mt-auto mx-auto">
      <img src="../../assets/images/skateboard.gif" alt="" class="w-3/4 md:w-2/4"/>
    </div>
  </div>
  <div v-else class="text-center max-w-xs mx-auto">
    <div v-if="variant === 'bounce'" class="loader bounce">
      <span class="circle"></span>
      <span class="circle"></span>
      <span class="circle"></span>
    </div>

    <div v-if="variant === 'pulse'" class="loader pulse">
      <span class="circle"></span>
    </div>

    <p v-if="current" class="mt-10 text-gray-600 font-semibold text-lg">{{ current }}</p>
    <p v-if="subtext" class="text-gray-500 mt-3">{{ subtext }}</p>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    variant: {
      type: String,
      default: 'skateboard'
    },
    text: {
      type: String,
    },
    subtext: {
      type: String
    },
    texts: Array,
    timeout: {
      type: Number,
      default: 3000,
    },
    loop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      current: '',
      index: 0,
      interval: null
    }
  },
  created() {
    if (this.texts) {
      this.index = 0;
      this.current = this.texts[this.index];
      this.index++;
      this.interval = setInterval(() => {
        const current = this.texts?.[this.index];
        if (!current) {
          if (this.loop) {
            this.index = 0;
            this.current = this.texts[this.index];
            this.index++;
          } else {
            clearInterval(this.interval);
          }
        } else {
          this.current = current;
          this.index++;
        }
      }, this.timeout);
    } else if (this.text) {
      this.current = this.text;
    }
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
  }
}
</script>

<style lang="scss" scoped>
.loader {
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.pulse {
    .circle {
      display: block;
      width: 100px;
      height: 100px;
      background: #f9f9f9;
      border-radius: 50%;
      position: relative;

      &::after,
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100px;
        height: 100px;
        background: var(--color-primary);
        border-radius: 50%;
        animation: pulse 3s linear infinite;
        opacity: 0;
      }

      &::before {
        animation: pulse 2s linear 2.3s infinite;
      }
    }
  }

  &.bounce {
    .circle {
      width: 10px;
      height: 10px;
      background: #ccc;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }

    .circle:first-child {
      animation: bounce 0.5s infinite 0.4s alternate ease-in-out;
    }

    .circle:nth-child(2) {
      margin-left: -30px;
      background: #ddd;
      animation: bounce 0.5s infinite alternate ease-in-out;
    }

    .circle:nth-child(3) {
      margin-left: 30px;
      background: #bbb;
      animation: bounce 0.5s infinite 0.6s alternate ease-in-out;
    }
  }

  @keyframes pulse {
    1% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1.3);
      opacity: 0;
    }
  }
  @keyframes bounce {
    1%,
    100% {
      transform: translate(-50%, -50%) translateY(0);
    }
    100% {
      transform: translate(-50%, -50%) translateY(-10px);
    }
  }
}
</style>
