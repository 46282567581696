var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"inline-flex justify-center items-center rounded-md outline-none cursor-pointer transition-all duration-300 whitespace-nowrap",class:{
      'bg-red-600 hover:bg-red-700 focus:bg-red-700 text-white': _vm.variant === 'danger',
      'bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 text-white': _vm.variant === 'primary',
      'bg-gray-100 hover:bg-gray-200 focus:bg-gray-200 text-gray-800': _vm.variant === 'secondary',
      'bg-gray-800 hover:bg-gray-900 focus:bg-gray-900 text-white': _vm.variant === 'dark',
      'bg-gray-100 hover:bg-gray-200 focus:bg-gray-200 text-gray-800': _vm.variant === 'light',
      'bg-transparent border border-blue-600 text-blue-600 hover:bg-blue-700 hover:text-white': _vm.variant === 'outline',
      'bg-transparent border border-red-600 text-red-600 hover:bg-red-700 hover:text-white': _vm.variant === 'outline-danger',
      'px-5 py-2': _vm.size === 'md',
      'py-1 px-4 text-md': _vm.size === 'sm',
      'py-3 px-8': _vm.size === 'lg',
      'loading': _vm.loading, 'opacity-50 cursor-not-allowed' : _vm.disabled
    },attrs:{"type":_vm.type,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }