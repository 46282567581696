var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-6 lg:px-12 pb-8"},[_c('SectionHeader',{attrs:{"title":"Provide Next of Kin","subtitle":"Please fill in all fields correctly"}}),_c('form',[_c('ValidationObserver',{ref:"observer"},[_c('div',{staticClass:"flex flex-col space-y-4"},[_c('ValidationProvider',{attrs:{"name":"Full name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label-color flex mb-2 text-gray-600"},[_vm._v("Full name")]),_c('AppInput',{staticClass:"w-full",attrs:{"type":"text","errors":errors},model:{value:(_vm.values.full_name),callback:function ($$v) {_vm.$set(_vm.values, "full_name", $$v)},expression:"values.full_name"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Email address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label-color flex mb-2 text-gray-600"},[_vm._v("Email address")]),_c('AppInput',{staticClass:"w-full",attrs:{"type":"email","errors":errors},model:{value:(_vm.values.email),callback:function ($$v) {_vm.$set(_vm.values, "email", $$v)},expression:"values.email"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Phone number","rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label-color flex mb-2 text-gray-600"},[_vm._v("Phone number")]),_c('AppInput',{staticClass:"w-full",attrs:{"type":"text","errors":errors},model:{value:(_vm.values.phone),callback:function ($$v) {_vm.$set(_vm.values, "phone", $$v)},expression:"values.phone"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppTextArea',{attrs:{"placeholder":"Home/Street address","errors":errors},model:{value:(_vm.values.address),callback:function ($$v) {_vm.$set(_vm.values, "address", $$v)},expression:"values.address"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Relationship","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label-color flex mb-2 text-gray-600"},[_vm._v("Relationship")]),_c('AppSelect',{attrs:{"name":"relationship","label":"Relationship","errors":errors},model:{value:(_vm.values.relationship),callback:function ($$v) {_vm.$set(_vm.values, "relationship", $$v)},expression:"values.relationship"}},[_c('option',{attrs:{"value":"spouse"}},[_vm._v("Spouse")]),_c('option',{attrs:{"value":"sibling"}},[_vm._v("Sibling")]),_c('option',{attrs:{"value":"child"}},[_vm._v("Child")]),_c('option',{attrs:{"value":"relative"}},[_vm._v("Relative")]),_c('option',{attrs:{"value":"friend"}},[_vm._v("Friend")])])]}}])})],1),_c('div',{staticClass:"flex mt-10"},[_c('AppButton',{attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Next")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }