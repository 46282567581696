<template>
  <div class="px-6 lg:px-12 pb-8">
    <SectionHeader
      title="Let's know about you"
      subtitle="Please fill in all fields correctly"
    />
    <form>
      <ValidationObserver ref="observer">
        <div class="flex flex-col space-y-4">
          <div>
            <label class="input-label-color flex mb-2 text-gray-600">Full name</label>
            <AppInput :value="values.full_name" type="text" class="w-full" disabled/>
          </div>
          <div>
            <label class="input-label-color flex mb-2 text-gray-600">Email address</label>
            <AppInput :value="values.email" type="email" class="w-full" disabled/>
          </div>
          <div>
            <label class="input-label-color flex mb-2 text-gray-600">Phone number</label>
            <AppInput :value="values.phone" type="text" class="w-full" disabled/>
          </div>
          <ValidationProvider name="Date of birth" rules="required" v-slot="{ errors }">
            <label class="input-label-color flex mb-2 text-gray-600">Date of birth</label>
            <AppInput
              v-model="values.date_of_birth" type="date" :errors="errors" class="w-full" :disabled="loading"
            />
          </ValidationProvider>
          <ValidationProvider name="Gender" rules="required" v-slot="{ errors }">
            <label class="input-label-color flex mb-2 text-gray-600">Gender</label>
            <AppSelect v-model="values.gender" name="gender" label="Gender" :errors="errors">
              <option value="0">Male</option>
              <option value="1">Female</option>
            </AppSelect>
          </ValidationProvider>
        </div>
        <div class="flex mt-10">
          <AppButton @click="submit()" :loading="loading">Next</AppButton>
        </div>
      </ValidationObserver>
    </form>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppSelect from "@/components/global/Select";
import AppInput from "@/components/global/Input";
import AppButton from "@/components/global/Button";

export default {
  name: "Profile",
  components: { AppButton, AppInput, AppSelect, SectionHeader },
  data() {
    return {
      loading: false,
      values: {
        full_name: '',
        email: '',
        phone: '',
        date_of_birth: '',
        gender: '',
      }
    }
  },
  created() {
    const { profile, config } = this.$store.state;
    if (!config.show_profile) return this.$emit('skip');
    this.values = { ...profile };
  },
  methods: {
    async submit() {
      if (!(await this.$refs.observer.validate())) return;
      this.$store.commit("profile", { ...this.values });
      this.$emit("done");
    }
  }
}
</script>
