<template>
  <div class="h-full flex flex-col">
    <div class="flex justify-between px-6 pt-6 lg:pt-10 lg:px-12">
      <span></span>
      <button
        @click="cancel()" v-if="inIframe"
        class="w-8 h-8 rounded-full flex justify-center items-center border border-red-500 text-red-500 hover:bg-red-50"
      >
        <i class="fa fa-times"></i>
      </button>
    </div>

    <div class="h-full flex flex-col md:h-auto md:my-auto">
      <Loader
        v-if="loading"
        class="my-auto"
        text="Please wait.."
      />
      <template v-else>
        <Offers
          v-if="view === 'offers'"
          @done="pushView('pending-review')"
        />
        <PendingReview
          v-if="view === 'pending-review'"
          @done="pushView('signature')"
        />
        <Signature
          v-if="view === 'signature'"
          @done="pushView('card')"
        />
        <RepaymentCard
          v-if="view === 'card'"
          @done="pushView('payment')"
        />
        <VirtualAccountPayment
          v-if="view === 'payment'"
          @done="pushView('connect-whatsapp')"
        />
        <ConnectWhatsapp
          v-if="view === 'connect-whatsapp'"
          @done="next()"
        />
        <Modal v-if="modal === 'fcn-confirmation'">
          <ConfirmFrequentlyCalledNumbers @done="pushView('offers')"/>
        </Modal>
      </template>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/global/Modal.vue";
import Offers from "@/components/sections/Offers.vue";
import Loader from "@/components/global/Loader.vue";
import view from '@/mixins/view.js';
import Signature from "@/components/sections/Signature.vue";
import RepaymentCard from "@/components/sections/RepaymentCard.vue";
import ConfirmFrequentlyCalledNumbers from "@/components/sections/ConfirmFrequentlyCalledNumbers.vue";
import VirtualAccountPayment from "@/components/sections/VirtualAccountPayment.vue";
import ConnectWhatsapp from "@/components/sections/ConnectWhatsapp.vue";
import PendingReview from "@/components/sections/PendingReview.vue";

export default {
  name: "PostAnalysis",
  components: {
    PendingReview,
    ConnectWhatsapp,
    VirtualAccountPayment,
    ConfirmFrequentlyCalledNumbers,
    RepaymentCard,
    Signature,
    Loader,
    Offers,
    Modal
  },
  mixins: [view],
  data() {
    return {
      loading: false,
      inIframe: false
    };
  },
  created() {
    this.inIframe = window.self !== window.top;
    const { stage, offer, payment } = this.$store.state;
    if (stage === 'offers' && offer) this.pushView('offers');
    else if (stage === 'offer-selected') this.pushView('pending-review');
    else if (stage === 'signature') this.pushView('signature');
    else if (stage === 'card') this.pushView('card');
    else if (stage === 'payment' && payment) this.pushView('payment');
    else if (stage === 'upfront-paid') this.pushView('connect-whatsapp');
    else if (stage === 'completed') this.next();
    else {
      this.pushView('offers');
    }
  },
  methods: {
    next() {
      this.$emit('done');
    },
    cancel() {
      this.$bus.$emit('cancel');
    },
  }
};
</script>
