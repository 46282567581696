<template>
  <div class="my-auto">
    <template v-if="offers.length">
      <SectionHeader
        title="Congratulations"
        subtitle="You are eligible for the following offers, select one below to continue"
      />
      <swiper ref="mySwiper" :options="swiperOptions">
        <template v-for="(offer, i) in offers">
          <swiper-slide :key="i">
            <div
              @click="selectOffer(offer)"
              class="shadow-md border rounded-lg p-6 cursor-pointer h-80 flex flex-col bg-orange-50 border-orange-100"
            >
              <p class="text-gray-600">Pay</p>
              <div class="text-xl font-semibold">{{ offer.upfront | currency }} <small
                class="text-gray-600">now</small></div>
              <div v-if="offer.duration === 1" class="text-gray-700">
                and {{ offer.monthly_repayment | currency }} next month
              </div>
              <div v-else class="text-gray-700">
                and {{ offer.monthly_repayment | currency }}/mo for {{ offer.duration }} months
              </div>
              <div class="flex justify-end items-center mt-auto text-gray-600">
                <i class="fa fa-user mr-2"></i> {{ lender ? lender.name : offer.lender }}
              </div>
            </div>
          </swiper-slide>
        </template>
        <div slot="pagination" class="swiper-pagination"></div>
      </swiper>

      <Modal v-if="selectedOffer">
        <div class="my-auto">
          <SectionHeader
            :subtitle="`I agree that I will pay ${formatCurrency(selectedOffer.upfront)} now and pay ${formatCurrency(selectedOffer.monthly_repayment)} in ${selectedOffer.duration} month(s)`"
            title="Confirm offer"
          />
          <div class="flex mt-6">
            <AppButton :loading="accepting" @click="$emit('accept', selectedOffer)">Accept</AppButton>
            <button class="ml-2 text-red-500 py-1 px-3" @click="selectedOffer = null">Cancel</button>
          </div>
        </div>
        <div class="flex justify-end mt-auto md:hidden">
          <img src="../../assets/images/approve.svg" alt="" class="w-56"/>
        </div>
      </Modal>
    </template>
  </div>
</template>

<script>
import Modal from "@/components/global/Modal.vue";
import AppButton from "@/components/global/Button.vue";
import { formatCurrency } from "@/lib/utils.js";
import SectionHeader from "@/components/global/SectionHeader.vue";

export default {
  name: "SingleOffer",
  components: { SectionHeader, AppButton, Modal },
  props: {
    offer: {
      type: Object,
      required: true
    },
    accepting: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      offers: [],
      selectedOffer: null,
      swiperOptions: {
        slidesPerView: 1.5,
        spaceBetween: 30,
      },
      payment: { amount: 0, upfront: false },
      formatCurrency,
      lender: this.$store.state.lender,
    }
  },
  async created() {
    this.handleGenerateOffer(this.offer);
  },
  methods: {
    handleGenerateOffer(offer) {
      const { monthly_repayment, upfront, duration, amount, total_repayment, lender, interest } = offer;
      this.offers = [
        {
          monthly_repayment, upfront, duration,
          loan_amount: amount, repayment_amount: total_repayment,
          tenor_type: 2, lender, interest
        }
      ]
    },
    selectOffer(offer) {
      this.selectedOffer = { ...offer };
    },
  },
}
</script>
