<template>
  <div class="flex flex-col items-start justify-center mt-10 md:-mt-8 text-left px-6 lg:px-12">
    <img src="@/assets/images/time-lady.svg" alt="questions" class="w-52 transform -rotate-45"/>
    <h2 class="text-3xl font-medium mt-12 max-w-xs text-gray-800">
      Answer as many questions as possible
    </h2>
    <ul class="list-disc list-inside mt-3 opacity-80">
      <li>Get your Debit card (Master card/Visa)</li>
      <li>Provide BVN & a few Kyc data</li>
      <li>Digitally send your bank statement</li>
    </ul>
    <AppButton @click="$emit('done')" class="mt-6">Let's start</AppButton>
  </div>
</template>

<script>
import AppButton from "@/components/global/Button.vue";

export default {
  name: "QuestionsAlert",
  components: { AppButton },
  created() {
    if (!this.$store.state.config.show_questions) return this.$emit('skip');
  }
}
</script>
