<template>
  <div class="h-full flex flex-col">
    <div class="px-6 lg:px-12 pb-8">
      <SectionHeader
        title="Provide numbers of people that trust you well"
        subtitle="Verification will be sent via WhatsApp, ensure you notify the owners of these numbers of incoming message from us"
      />

      <div class="flex flex-col space-y-4 mt-6">
        <div v-for="i in count" :key="i" class="space-y-4 border border-gray-300 px-4 py-4 rounded-lg">
          <AppInput placeholder="Enter full name" v-model="numbers[i - 1].name"/>
          <AppInput placeholder="Enter phone number" v-model="numbers[i - 1].phone"/>
        </div>
      </div>

      <label class="flex items-center mt-6">
        <input v-model="confirmed" class="mr-2" type="checkbox"> I have notified them
      </label>

      <div class="flex mt-8">
        <AppButton @click="next()" :disabled="!valid || !confirmed">Continue</AppButton>
      </div>
      <p @click="$emit('done')" class="mt-4 text-gray-400 cursor-pointer">Skip</p>
    </div>

    <div class="flex justify-end mt-auto md:hidden">
      <img src="../../assets/images/whatsapp-phone-half.png" alt="" class="w-52"/>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppInput from "@/components/global/Input";
import AppButton from "@/components/global/Button";

export default {
  name: "FrequentlyCalledNumbers",
  components: { AppButton, AppInput, SectionHeader },
  data() {
    return {
      confirmed: false,
      numbers: JSON.parse(JSON.stringify([...this.$store.state.frequently_called_numbers])),
      count: Math.min(this.$store.state.config.no_frequently_called_numbers, 2),
    }
  },
  created() {
    if (this.$store.state.config.no_frequently_called_numbers < 1) return this.$emit('skip');
    const { recently, twoWeeks, oneMonth, threeMonths } = this.$store.getters['lastApplied'];
    if (recently || twoWeeks) return this.$emit('skip');
    else if (oneMonth || threeMonths) this.count = 1;
  },
  methods: {
    next() {
      if (!this.valid) return;
      this.$store.commit('patch', { frequently_called_numbers: this.numbers });
      this.$emit('done');
    }
  },
  computed: {
    valid() {
      const phone = this.$store.state.profile.phone;
      const cb = (n) => n.phone?.length === 11 && n.phone?.match(/(^[0][789]\d{9}$)/) && n.phone !== phone;
      return this.numbers.slice(0, this.count).every(cb) && this.numbers[0].phone !== this.numbers[1].phone;
    }
  }
}
</script>
