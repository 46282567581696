<template>
  <div class="my-auto">
    <template v-if="offers.length">
      <p class="mb-6 text-lg text-gray-700">Select an offer below</p>
      <swiper ref="mySwiper" :options="swiperOptions">
        <template v-for="(offer, i) in offers">
          <swiper-slide :key="i">
            <div
              @click="selectOffer(offer)"
              class="bg-gray-50 shadow-md border rounded-lg p-6 cursor-pointer h-80 flex flex-col"
            >
              <p class="text-gray-600">Pay</p>
              <div class="text-xl font-semibold">{{ offer.upfront | currency }} <small
                class="text-gray-600">now</small></div>
              <div v-if="offer.duration === 1" class="text-gray-700">
                and {{ offer.monthly_repayment | currency }} next month
              </div>
              <div v-else class="text-gray-700">
                and {{ offer.monthly_repayment | currency }}/mo for {{ offer.duration }} months
              </div>
              <div class="flex justify-end items-center mt-auto text-gray-600">
                <i class="fa fa-user mr-2"></i> {{ lender ? lender.name : offer.lender }}
              </div>
            </div>
          </swiper-slide>
        </template>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <Modal v-if="selectedOffer">
        <div class="my-auto">
          <SectionHeader
            title="Confirm offer"
            :subtitle="`I agree that I will pay ${formatCurrency(selectedOffer.upfront)} now and pay ${formatCurrency(selectedOffer.monthly_repayment)} in ${selectedOffer.duration} ${tenor_type.text}`"
          />
          <div class="flex gap-4 items-center">
            <p class="text-sm">Repayment frequency:</p>
            <div class="flex gap-4 items-center">
              <div
                v-for="f in frequencies" :key="f.id" @click="changeTenorType(f.id)"
                class="px-2 py-1 text-sm border-2 rounded-lg bg-gray-100 cursor-pointer"
                :class="{ 'border-2 border-blue-500 shadow': selectedOffer.tenor_type === f.id }"
              >
                {{ f.adverb }}
              </div>
            </div>
          </div>
          <div class="flex mt-6">
            <AppButton @click="$emit('accept', selectedOffer)" :loading="accepting">Accept</AppButton>
            <button @click="selectedOffer = null" class="ml-2 text-red-500 py-1 px-3">Cancel</button>
          </div>
        </div>
      </Modal>
    </template>
  </div>
</template>

<script>
import Modal from "@/components/global/Modal.vue";
import AppButton from "@/components/global/Button.vue";
import { formatCurrency, roundToNearestTen } from "@/lib/utils.js";
import SectionHeader from "@/components/global/SectionHeader.vue";

export default {
  name: "RentOffers",
  components: { SectionHeader, AppButton, Modal },
  props: {
    offer: {
      type: Object,
      required: true
    },
    accepting: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      offers: [],
      selectedOffer: null,
      swiperOptions: {
        slidesPerView: 1.5,
        spaceBetween: 30,
      },
      frequencies: [
        { id: 2, text: 'month(s)', adverb: 'Monthly' },
        { id: 4, text: 'week(s)', adverb: 'Weekly' },
      ],
      payment: { amount: 0, upfront: false },
      formatCurrency,
      lender: this.$store.state.lender
    }
  },
  async created() {
    this.handleGenerateOffer(this.offer);
  },
  methods: {
    handleGenerateOffer(offer) {
      this.offers = this.generateOffers(offer);
    },
    generateOffers(offer) {
      let plans = [];
      const { duration, upfront, total_repayment, amount } = offer;
      if (!total_repayment) return [];
      const price = amount + upfront;
      const { recommended_upfront_cap } = this.$store.state.config;
      {
        const monthly_repayment = roundToNearestTen(amount / duration);
        plans.push({
          loan_amount: offer.amount,
          repayment_amount: monthly_repayment * duration,
          duration,
          monthly_repayment,
          upfront: roundToNearestTen(upfront),
          tenor_type: 2
        });
      }
      block1: {
        if (recommended_upfront_cap) break block1;
        const _upfront = upfront * 2;
        const repayment_amount = price - _upfront;
        plans.push({
          loan_amount: repayment_amount,
          repayment_amount,
          upfront: _upfront,
          duration,
          monthly_repayment: repayment_amount / duration,
          tenor_type: 2
        })
      }
      block2: {
        if (recommended_upfront_cap) break block2;
        const _upfront = upfront * 1.5;
        const repayment_amount = price - _upfront;
        plans.push({
          loan_amount: repayment_amount,
          repayment_amount,
          upfront: _upfront,
          duration,
          monthly_repayment: repayment_amount / duration,
          tenor_type: 2
        })
      }
      return plans.map((plan, i) => ({ id: i, ...plan }));
    },
    selectOffer(offer) {
      const { can_change_repayment_frequency } = this.$store.state.config;
      if (!can_change_repayment_frequency) return this.$emit('accept', offer);
      this.selectedOffer = { ...offer };
      this.changeTenorType(offer.tenor_type);
    },
    changeTenorType(v) {
      this.selectedOffer.tenor_type = v;
      const offer = this.offers.find(offer => offer.id === this.selectedOffer.id);
      if (v === 4) {
        this.selectedOffer.duration = offer.duration * 4
        this.selectedOffer.monthly_repayment = roundToNearestTen(offer.monthly_repayment / 4)
      }
      if (v === 1) {
        this.selectedOffer.duration = offer.duration * 30
        this.selectedOffer.monthly_repayment = roundToNearestTen(offer.monthly_repayment / 30)
      }
      if (v === 2) {
        this.selectedOffer.duration = offer.duration
        this.selectedOffer.monthly_repayment = offer.monthly_repayment
      }
    },
  },
  computed: {
    tenor_type() {
      return this.frequencies.find(f => this.selectedOffer.tenor_type === f.id) || {}
    }
  }
}
</script>
