<template>
  <div class="h-full flex flex-col">
    <Loader v-if="loading" :text="loading" class="my-auto px-6 lg:px-12 pb-8"/>
    <div v-else class="my-auto">
      <div class="px-6 lg:px-12 pb-8">
        <template v-if="view === 'new'">
          <SectionHeader
            title="Setup your debit card"
            :subtitle="`Provide your card details for direct debit, a small fee of N${amount} will be deducted from your card`"
          />
          <div
            v-if="cards.length" @click="view = 'cards'"
            class="bg-gray-100 hover:bg-gray-200 text-gray-700 px-6 py-2 rounded-full inline-flex items-center justify-center cursor-pointer border border-dashed mb-4"
          >
            <i class="fa fa-credit-card mr-3"></i>View cards
          </div>
          <br>
          <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
          <template v-if="!card">
            <Paystack
              v-if="method === 'paystack'" :amount="amount" :email="$store.state.profile.email"
              @done="paymentDone($event)"
            >
              <div
                class="bg-gray-800 hover:bg-gray-900 text-white px-36 py-16 rounded-lg flex flex-col items-center text-center justify-center"
              >
                <i class="fa fa-plus fa-2x mb-2 text-gray-300"></i>Add card
              </div>
            </Paystack>
            <Flutterwave
              v-if="method === 'flutterwave'" :amount="amount" @done="paymentDone($event)"
              :customer="{ name: $store.state.profile.full_name, email: $store.state.profile.email, phone_number: $store.state.profile.phone }"
            >
              <div
                class="bg-gray-800 hover:bg-gray-900 text-white px-36 py-16 rounded-lg flex flex-col items-center text-center justify-center"
              >
                <i class="fa fa-plus fa-2x mb-2 text-gray-300"></i>
                Add card
              </div>
            </Flutterwave>
          </template>

          <div v-else>
            <AppButton class="mt-10">Continue</AppButton>
          </div>
        </template>
        <template v-if="view === 'cards'">
          <SectionHeader
            title="Setup your debit card"
            :subtitle="`Select a card below to setup direct debit for this request`"
          />
          <div
            @click="view = 'new'"
            class="bg-gray-100 hover:bg-gray-200 text-gray-700 px-6 py-2 rounded-full inline-flex items-center justify-center cursor-pointer border border-dashed mb-4"
          >
            <i class="fa fa-plus mr-3"></i>Add new card
          </div>
          <Message v-if="error" variant="error" class="mb-6">{{ error }}</Message>
          <swiper ref="mySwiper" :options="{ slidesPerView: 1.5, spaceBetween: 20 }">
            <template v-for="card in cards">
              <swiper-slide :key="card.card_id">
                <div
                  @click="initChargeCard(card)"
                  class="bg-gray-800 hover:bg-gray-900 text-white px-8 py-6 rounded-xl flex flex-col items-stretch cursor-pointer"
                >
                  <p class="text-yellow-500 text-sm uppercase">{{ card.bank }}</p>
                  <div class="text-xl my-auto py-8">
                    • • • •&nbsp;&nbsp;&nbsp;• • • •&nbsp;&nbsp;&nbsp;• • • •&nbsp;&nbsp;{{ card.last4 }}
                  </div>
                  <div class="flex justify-between items-center">
                    <p class="text-sm">EXP: {{ card.expiry_date }}</p>
                    <MasterCardLogo/>
                  </div>
                </div>
              </swiper-slide>
            </template>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppButton from "@/components/global/Button";
import Paystack from "@/components/global/Paystack";
import Flutterwave from "@/components/global/Flutterwave";
import Loader from "@/components/global/Loader";
import ajax from "@/mixins/ajax";
import Message from "@/components/global/Message.vue";
import MasterCardLogo from "@/components/icons/MasterCardLogo.vue";

export default {
  name: "RepaymentCard",
  components: { MasterCardLogo, Message, Loader, Flutterwave, AppButton, SectionHeader, Paystack },
  mixins: [ajax],
  data() {
    return {
      card: null,
      cards: [],
      view: '',
      method: 'flutterwave',
      token: null,
      amount: 5,
      paystack: { amount: 1, key: null }
    }
  },
  created() {
    const { config, stage, payment } = this.$store.state;
    if (!config.tokenize_card) return this.$emit('done')
    if (payment && !payment.upfront) return this.$emit('done');
    this.getCards();
    if (stage !== 'card') this.$store.dispatch('updateStage', { stage: 'card' });
  },
  methods: {
    init() {
      this.setLoading('Initializing..');
      this.$http.post('https://mobile.creditclan.com/api/v3/card/tokenization', {
        request_id: this.$store.state.request_id
      }).then(({ data }) => {
        const { paystack, flutterwave } = data.data;
        if (paystack.must_added) this.method = 'paystack';
        else if (flutterwave.must_added) this.method = 'flutterwave';
        this.token = data.token;
        this.setLoading('');
      }).catch(() => {
        this.setError('An error occurred, please try again', true);
        this.setRetry(() => this.check());
      });
    },
    check() {
      this.setLoading('Checking for card..');
      this.$http.post('https://mobile.creditclan.com/api/v3/loan/checklists', {
        request_id: this.$store.state.request_id, token: this.$store.state.token
      }).then(res => {
        if (!res.data.data.card) this.init();
        this.$emit('done');
      }).catch(() => {
        this.setError('An error occurred, please try again', true);
        this.setRetry(() => this.check());
      });
    },
    async getCards() {
      this.setLoading('Getting cards..');
      try {
        const res = await this.$http.post('/user/cards', { token: this.$store.state.token });
        this.cards = res.data.data
        this.view = this.cards.length ? 'cards' : 'new';
        this.init();
      } catch (e) {
        this.setError(e.response ? e.response.data.message : e.message || 'An error occurred, please try again');
        this.setRetry(() => this.getCards())
      }
    },
    initChargeCard(card) {
      try {
        this.setLoading('Charging card..');
        const { EXP_YEAR, EXP_MONTH } = card || {};
        const now = new Date();
        if (+EXP_YEAR > now.getFullYear()) return this.chargeCard(card);
        if (+EXP_YEAR === now.getFullYear() && (+EXP_MONTH - now.getMonth() + 1) >= 6) return this.chargeCard(card);
        this.setError('Sorry this card cannot be used for this request, please use a different card');
        this.setBack(() => this.reset());
      } catch (e) {
        this.setError(`${ e.message }. Try adding a new card if error persists`);
      }
    },
    async chargeCard(card) {
      this.$mixpanel.track('Attempted to charge card', {
        platform: this.$store.state.config.platform,
        request_id: this.$store.state.request_id,
      });
      try {
        const res = await this.$http.post('/customer/paystack/charge/card', {
          token: this.$store.state.token,
          request_id: this.$store.state.request_id,
          account_card_id: card.ACCOUNT_CARD_ID
        });
        if (res.data.status) {
          this.$mixpanel.track('Successfully charged card', {
            platform: this.$store.state.config.platform,
            request_id: this.$store.state.request_id,
          });
          return this.$emit('done');
        }
        this.setError(res.data.message || 'An error occurred, please try again', true);
        this.setBack(() => this.reset());
        this.setRetry(() => this.initChargeCard(card));
      } catch (e) {
        const message = e?.response?.data?.message || e.message || 'An error occurred, please try again!';
        this.setError(message);
        this.$mixpanel.track('Error while charging card', {
          platform: this.$store.state.config.platform,
          request_id: this.$store.state.request_id,
          error: message,
        });
        this.setBack(() => this.reset());
        this.setRetry(() => this.initChargeCard(card));
      }
    },
    async paymentDone(response) {
      this.setLoading('Submitting..');
      try {
        if (this.method === 'paystack') await this.submitPaystack(response.reference);
        else if (this.method === 'flutterwave') await this.submitFlutterwave(response);
      } catch (e) {
        const message = e?.response?.data?.message || e.message || 'An error occurred, please try again!';
        this.$mixpanel.track('Error while submitting payment', {
          platform: this.$store.state.config.platform,
          request_id: this.$store.state.request_id,
          error: message,
        });
        this.setError(message);
        this.setRetry(() => this.paymentDone(response));
      }
      this.setLoading('');
    },
    async submitPaystack(reference) {
      const res = await this.$http.post('/tokennization/add/newcard', { token: this.token, reference });
      if (res.data.status) return this.next();
      throw new Error(res.data.message);
    },
    async submitFlutterwave(data) {
      const res = await this.$http.post('/tokennization/add/newcard', { token: this.token, data });
      if (res.data.status) return this.next();
      throw new Error(res.data.message);
    },
    next() {
      this.$emit('done');
    }
  }
}
</script>
