<template>
  <div class="my-auto">
    <template v-if="offers.length">
      <p class="mb-6 text-lg text-gray-700">Select an offer below</p>
      <swiper ref="mySwiper" :options="swiperOptions">
        <template v-for="(offer, i) in offers">
          <swiper-slide :key="i">
            <div class="bg-gray-50 shadow-md border rounded-lg p-6 cursor-pointer h-80 flex flex-col">
              <i class="fa fa-utensils-alt"></i>
              <div class="text-xl font-semibold">{{ offer.no_of_meals }} more meals</div>
              <div class="text-gray-700 mt-2">
                in this and any other restaurant up to a total of {{ offer.limit | currency }} per month
              </div>
              <div class="flex justify-end items-center mt-auto text-gray-600">
                <i class="fa fa-user mr-2"></i> {{ lender ? lender.name : offer.lender }}
              </div>
            </div>
          </swiper-slide>
        </template>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <Modal v-if="selectedOffer">
        <div class="my-auto">
          <SectionHeader
            title="Confirm offer"
            :subtitle="`I agree that I will pay ${formatCurrency(selectedOffer.upfront)} now and pay ${formatCurrency(selectedOffer.monthly_repayment)} in ${selectedOffer.duration} ${tenor_type.text}`"
          />
          <div class="flex gap-4 items-center">
            <p class="text-sm">Repayment frequency:</p>
            <div class="flex gap-4 items-center">
              <div
                v-for="f in frequencies" :key="f.id"
                class="px-2 py-1 text-sm border-2 rounded-lg bg-gray-100 cursor-pointer"
                :class="{ 'border-2 border-blue-500 shadow': selectedOffer.tenor_type === f.id }"
              >
                {{ f.adverb }}
              </div>
            </div>
          </div>
          <div class="flex mt-6">
            <AppButton @click="$emit('accept', selectedOffer)" :loading="accepting">Accept</AppButton>
            <button @click="selectedOffer = null" class="ml-2 text-red-500 py-1 px-3">Cancel</button>
          </div>
        </div>
      </Modal>
    </template>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader.vue";
import AppButton from "@/components/global/Button.vue";
import Modal from "@/components/global/Modal.vue";
import { formatCurrency } from "@/lib/utils.js";

export default {
  name: "EatOffers",
  components: { SectionHeader, AppButton, Modal },
  props: {
    offer: {
      type: Object,
      required: true
    },
    accepting: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      offers: [],
      selectedOffer: null,
      swiperOptions: {
        slidesPerView: 1.5,
        spaceBetween: 30,
      },
      frequencies: [
        { id: 2, text: 'month(s)', adverb: 'Monthly' },
        { id: 4, text: 'week(s)', adverb: 'Weekly' },
      ],
      payment: { amount: 0, upfront: false },
      formatCurrency,
      lender: this.$store.state.lender
    }
  },
  async created() {
    this.handleGenerateOffer(this.offer);
  },
  methods: {
    handleGenerateOffer(offer) {
      this.offers = this.generateOffers(offer);
    },
    generateOffers(offer) {
      const { effective_income } = offer;
      const offer1 = {
        no_of_meals: Math.min((effective_income / (5 * 2500)), 20),
        limit: Math.min((effective_income / 5), 50000),
      };
      const offer2 = {
        no_of_meals: Math.min((effective_income / (5 * 2500 * 2))),
        limit: Math.min((effective_income / 5), 50000),
      };
      console.log({ offer1, offer2 })
      return [offer1, offer2];
    }
  }
}
</script>
