import mixpanel from 'mixpanel-browser';

const token = process.env.VUE_APP_MIXPANEL_TOKEN;
const debug = process.env.NODE_ENV === 'development';

export default {
  install: (Vue) => {
    const config = { debug };
    Vue.prototype.$mixpanel = mixpanel
    Vue.prototype.$mixpanel.init(token, config)
  }
};
