<template>
  <div>
    <div class="flex bg-gray-100 space-x-1 p-1 rounded-lg mt-4">
      <button
        @click="tab = 'internet'"
        class="rounded-md px-2 py-1 flex-1 transition duration-300 opacity-70 text-sm"
        :class="{ 'bg-white shadow font-medium opacity-100': tab === 'internet'}"
      >
        Internet banking
      </button>
      <button
        @click="tab = 'mobile'"
        class="rounded-md px-2 py-1 flex-1 transition duration-300 opacity-70 text-sm"
        :class="{ 'bg-white shadow font-medium opacity-100': tab === 'mobile'}"
      >
        Mobile banking
      </button>
    </div>
    <div v-if="tab === 'internet'" class="mt-6">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <div class="rounded-lg p-6 cursor-pointer min-h-72 border bg-gray-100 border-gray-200 flex flex-col">
            <div>
              <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
                1
              </div>
            </div>
            <ul class="list-disc list-inside text-sm mt-8">
              <li>Log on to internet banking with your User ID and Password</li>
              <li>Click “My Accounts”</li>
              <li>Click “Send Statement to“</li>
              <li>Click “New Request”</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="rounded-lg p-6 cursor-pointer min-h-72 border bg-gray-100 border-gray-200 flex flex-col">
            <div>
              <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
                2
              </div>
            </div>
            <ul class="list-disc list-inside text-sm mt-8">
              <li>Select “Statement Account” and “Account to Debit”</li>
              <li>Select “Start” & “End” dates</li>
              <li>Select “role”. “Applicant” for self, “Sponsor” for others</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="rounded-lg p-6 cursor-pointer min-h-72 border bg-gray-100 border-gray-200 flex flex-col">
            <div>
              <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
                3
              </div>
            </div>
            <ul class="list-disc list-inside text-sm mt-8">
              <li>Enter full name(s) of applicant/persons under your sponsorship in the Applicants field.</li>
              <li>Select "Creditclan" from the destination list</li>
              <li>Confirm details captured are accurate and completed</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="rounded-lg p-6 cursor-pointer min-h-72 border bg-gray-100 border-gray-200 flex flex-col">
            <div>
              <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
                4
              </div>
            </div>
            <ul class="list-disc list-inside text-sm mt-8">
              <li>Enter “Answer to your secret question”</li>
              <li>Enter “Token code”</li>
              <li>Click the “Submit” button to send generated statement to your preferred Third Party.</li>
            </ul>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div v-if="tab === 'mobile'" class="mt-6">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <div class="rounded-lg p-6 cursor-pointer min-h-72 border bg-gray-100 border-gray-200 flex flex-col">
            <div>
              <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
                1
              </div>
            </div>
            <ul class="list-disc list-inside text-sm mt-8">
              <li>
                Download the GT-World application on your “Playstore” for Android phones, “Appworld” for BlackBerry
                phones and “Applestore” for IPhone
              </li>
              <li>Log on to Mobile Banking with your User ID and Password</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="rounded-lg p-6 cursor-pointer min-h-72 border bg-gray-100 border-gray-200 flex flex-col">
            <div>
              <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
                2
              </div>
            </div>
            <ul class="list-disc list-inside text-sm mt-8">
              <li>Slide your finger on your Mobile phone to display outstanding menu items</li>
              <li>Click “Account Statement” option on the “Banking” module</li>
              <li>Click “Send Statement to Third Party”</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="rounded-lg p-6 cursor-pointer min-h-72 border bg-gray-100 border-gray-200 flex flex-col">
            <div>
              <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
                3
              </div>
            </div>
            <ul class="list-disc list-inside text-sm mt-8">
              <li>Select “Statement Account” and “Account to Debit”</li>
              <li>Select “Start” & “End” dates</li>
              <li>Select “role”. “Applicant” for self, “Sponsor” for others</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="rounded-lg p-6 cursor-pointer min-h-72 border bg-gray-100 border-gray-200 flex flex-col">
            <div>
              <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
                4
              </div>
            </div>
            <ul class="list-disc list-inside text-sm mt-8">
              <li>Enter full name(s) of applicant/persons under your sponsorship in the Applicants field.</li>
              <li>Select "Creditclan" from the destination list</li>
              <li>Confirm details captured are accurate and completed</li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="rounded-lg p-6 cursor-pointer min-h-72 border bg-gray-100 border-gray-200 flex flex-col">
            <div>
              <div class="w-10 h-10 rounded-full flex justify-center items-center text-white bg-blue-100 bg-blue-300">
                5
              </div>
            </div>
            <ul class="list-disc list-inside text-sm mt-8">
              <li>Click the “Send Statement” button</li>
              <li>To authenticate your request, enter “Token code” or “four (4) digit PIN”</li>
              <li>Click the “Done” button to send generated statement to your preferred Third Party.</li>
            </ul>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  name: "GtInstructions",
  data: () => ({
    tab: 'mobile',
    swiperOptions: {
      slidesPerView: 1.5,
      spaceBetween: 30,
    }
  })
}
</script>
