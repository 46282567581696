<template>
  <div class="h-full flex flex-col">
    <template v-if="response">
      <SectionHeader
        subtitle="Click the button below to continue"
        title="Thank you for providing your bank statement"
      />
      <div class="flex mt-10">
        <AppButton @click="$emit('done')">Continue</AppButton>
      </div>
    </template>
    <template v-else>
      <template v-if="view === 'local'">
        <BSLocal :has-options="hasOptions" @done="localDone($event)" @options="showOptions()"/>
      </template>
      <template v-if="view === 'mono'">
        <BSMono @done="monoDone($event)" @failed="handleMonoFailed()" @options="showOptions()"/>
      </template>
      <template v-if="view === 'mbs'">
        <BSMbs @done="mbsDone($event)" @options="showOptions()" @failed="handleMbsFailed()"/>
      </template>
      <template v-if="view === 'home'">
        <SectionHeader
          subtitle="Creditclan has requested for your bank statement to process this request, click the button below to continue"
          title="Analyse bank statement"
        />
        <div class="flex mt-10">
          <AppButton @click="start()">Start</AppButton>
        </div>
        <button v-if="fallback" class="text-gray-600 mt-3 text-sm" @click="view = 'local'">Upload manually</button>
      </template>
    </template>
    <Modal v-if="modals.options">
      <div class="md:my-auto">
        <button
          class="w-8 h-8 rounded-full flex justify-center items-center border border-red-500 text-red-500 absolute top-5 right-5 hover:bg-red-50"
          @click="modals.options = false"
        >
          <i class="fa fa-times"></i>
        </button>
        <h3 class="text-lg font-semibold mb-8">Other available methods</h3>
        <div class="flex flex-col space-y-4">
          <div
            v-if="view !== 'mbs' && supportsMbs"
            class="border px-5 py-6 rounded-lg flex items-center cursor-pointer hover:bg-gray-50"
            @click="modals.options = false; view = 'mbs'"
          >
            <div>
              <div class="w-12 text-blue-600">
                <i class="fa fa-sms fa-2x"></i>
              </div>
            </div>
            <div>
              <h6 class="font-medium">Receive PIN from your bank</h6>
              <p class="text-sm mt-1 opacity-70">
                Provide a ticket id and password sent by your bank to authorize us to get your statement
              </p>
            </div>
          </div>
          <div
            v-if="view !== 'mono' && supportsMono"
            class="border px-5 py-6 rounded-lg flex items-center cursor-pointer hover:bg-gray-50"
            @click="modals.options = false; view = 'mono'"
          >
            <div>
              <div class="w-12 text-teal-600">
                <i class="fa fa-mobile-alt fa-2x"></i>
              </div>
            </div>
            <div>
              <h6 class="font-medium">Use internet banking</h6>
              <p class="text-sm mt-1 opacity-70">
                Kindly use your internet or mobile banking credentials to get statement.
              </p>
            </div>
          </div>
          <div
            v-if="view !== 'local'"
            class="border px-5 py-6 rounded-lg flex items-center cursor-pointer hover:bg-gray-50"
            @click="modals.options = false; view = 'local'"
          >
            <div>
              <div class="w-12 text-red-600">
                <i class="fa fa-file-pdf fa-2x"></i>
              </div>
            </div>
            <div>
              <h6 class="font-medium">Upload bank statement file</h6>
              <p class="text-sm mt-1 opacity-70">
                Kindly upload last 7months Pdf statement here.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end mt-auto md:hidden">
        <img src="../../assets/images/savings.svg" alt="" class="w-60"/>
      </div>
    </Modal>
  </div>
</template>

<script>
import SectionHeader from "@/components/global/SectionHeader";
import AppButton from "@/components/global/Button";
import BSLocal from "@/components/sections/BSLocal";
import BSMono from "@/components/sections/BSMono";
import BSMbs from "@/components/sections/BSMbs";
import mbsBanks from "@/lib/mbs-banks.js";
import Modal from "@/components/global/Modal.vue";

export default {
  name: "BankStatement",
  components: { Modal, BSMbs, BSMono, BSLocal, AppButton, SectionHeader },
  data() {
    return {
      view: 'online-banking',
      has_online_banking: 1,
      fallback: false,
      modals: {
        options: false,
      },
    }
  },
  created() {
    if (!this.$store.state.config.analyze_bank_statement) return this.$emit('skip');
    if (!this.$store.state.account.bank_code) return this.$emit('skip');
    const { recently, twoWeeks, oneMonth } = this.$store.getters['lastApplied'];
    if ((recently || twoWeeks || oneMonth) && this.$store.state.account.okra_id) {
      this.$store.commit('bs', {
        response: { ...this.$store.state.account?.okra_id ?? {} }
      });
      return this.$emit('skip');
    }
    this.start();
    this.$mixpanel.track('Started bank statement', {
      platform: this.$store.state.config.platform,
      bank_code: this.$store.state.account.bank_code,
      account_number: this.$store.state.account.account_number,
      bank_name: this.$store.getters['bankName'],
    });
  },
  methods: {
    start() {
      if (this.supportsMbs) this.view = 'mbs';
      else if (this.supportsMono) this.view = 'mono';
      else this.view = 'local';
    },
    showOptions() {
      if (this.hasOptions) this.modals.options = true;
    },
    handleMonoFailed() {
      this.view = 'local';
    },
    handleMbsFailed() {
      if (this.supportsMono) this.view = 'mono';
      else this.view = 'local';
    },
    localDone(transaction_id) {
      this.$store.commit('bs', {
        response: transaction_id ? {
          statement_source: 1,
          filename: { transaction_id },
          has_online_banking: false
        } : null
      });
      this.$emit('done');
    },
    monoDone(response) {
      this.$store.commit('bs', {
        response: {
          statement_source: 4,
          filename: response,
          has_online_banking: true
        }
      });
      this.$emit('done');
    },
    mbsDone({ statement_source, transaction_id }) {
      this.$store.commit('bs', {
        response: {
          statement_source,
          filename: { transaction_id },
          has_online_banking: false
        }
      });
      this.$emit('done');
    },
    cancel() {
      this.view = 'local';
      this.fallback = true;
    }
  },
  computed: {
    supportsMbs() {
      return mbsBanks.some(b => b.sortCode === this.$store.state.account.bank_code);
    },
    supportsMono() {
      const { account: { bank_code }, banks_mono } = this.$store.state;
      return !!banks_mono.find(bank => `${ bank.bank_code }` === `${ bank_code }`)?.mono_bank_data;
    },
    hasOptions() {
      return this.supportsMono || this.supportsMbs;
    },
    response() {
      return this.$store.state.bs.response;
    }
  }
}
</script>
