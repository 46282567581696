<template>
  <div class="h-full flex flex-col px-6 pt-6 lg:pt-10 lg:px-12">
    <Loader v-if="loading" :text="loading" class="my-auto"/>
    <div v-if="!loading" class="text-center mx-auto flex flex-col items-center my-auto">
      <div class="w-24 h-24 rounded-full bg-green-600 flex items-center justify-center">
        <i class="fa fa-check text-4xl text-white"></i>
      </div>
      <div class="px-5 mt-10 text-center">
        <p class="font-semibold text-lg">Application successful</p>
        <p class="text-gray-500 mt-3">We will get back to you within the hour</p>
        <div v-if="source === 'whatsapp'" class="flex justify-center">
          <AppButton @click="backToWhatsapp()" class="mt-8">
            <i class="fab fa-whatsapp mr-2"></i>
            Back to Whatsapp
          </AppButton>
        </div>
        <div v-if="inIframe" class="flex flex-col items-center mt-10">
          <button @click="close()" class="px-3 py-1 border border-red-500 text-red-500 rounded-lg">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import AppButton from "@/components/global/Button.vue";
import ajax from "@/mixins/ajax.js";
import Loader from "@/components/global/Loader.vue";

export default {
  name: 'Success',
  components: { Loader, AppButton },
  mixins: [ajax],
  data() {
    return {
      inIframe: false,
      source: this.$store.state.source,
      response: null
    };
  },
  created() {
    this.inIframe = window.self !== window.top;
    const { stage, request } = this.$store.state;
    if (stage !== 'completed') this.$store.dispatch('updateStage', { stage: 'completed' });
    if (`${ request.product_id }` === '29961') this.enrollHygeiaUser();
    else this.sendEvent();
  },
  methods: {
    sendEvent() {
      this.$mixpanel.track('Eligibility completed', {
        platform: this.$store.state.config.platform,
        request_id: this.$store.state.request_id,
      });
      const { request_id, profile: { user_id } } = this.$store.state;
      const data = { request_id, user_id };
      if (this.response) data.response = this.response;
      parent.postMessage({ id: 'cclan-completed', data }, '*');
    },
    close() {
      parent.postMessage({ id: 'cclan-close' }, '*');
    },
    backToWhatsapp() {
      window.open('https://wa.me/+2349053535333');
    },
    async enrollHygeiaUser() {
      try {
        this.setLoading('Please wait..');
        const { profile, home_address, request } = this.$store.state;
        const [FirstName, LastName] = profile.full_name.split(' ');
        const res = await this.$http.post('https://cc-eligibility-whatsapp.herokuapp.com/misc/hygeia', {
          payload: {
            "DataConsent": true,
            "PlanId": request.hygeia_product_id,
            FirstName, LastName,
            "DOB": profile.date_of_birth,
            "GenderId": +profile.gender + 1,
            "Email": profile.email,
            "Phone": profile.phone,
            "Address": home_address.address,
            "ImagePath": profile.profile_image,
          }
        }, { timeout: 120000 });
        this.response = res.data.response;
      } catch (e) {
        this.response = e?.response?.data ?? e?.message;
      }
      this.setLoading('');
      this.sendEvent();
    }
  }
}
</script>
