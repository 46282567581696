var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-6 lg:px-12 pb-8"},[_c('SectionHeader',{attrs:{"title":"Where do you work?","subtitle":"Please fill in all fields correctly"}}),_c('form',[_c('ValidationObserver',{ref:"observer"},[(_vm.error)?_c('Message',{staticClass:"mb-6",attrs:{"variant":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('div',{staticClass:"flex flex-col space-y-4"},[_c('ValidationProvider',{attrs:{"name":"occupation_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label-color flex mb-2 text-gray-600"},[_vm._v("Employment status")]),_c('AppSelect',{staticClass:"w-full",attrs:{"errors":errors},model:{value:(_vm.values.occupation_id),callback:function ($$v) {_vm.$set(_vm.values, "occupation_id", $$v)},expression:"values.occupation_id"}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v("Select employment status")]),_vm._l((_vm.occupations),function(status){return _c('option',{key:status.value,domProps:{"value":status.value}},[_vm._v(" "+_vm._s(status.text)+" ")])})],2)]}}])}),(_vm.values.occupation_id === '1')?[_c('ValidationProvider',{attrs:{"name":"Place of work","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label-color flex mb-2 text-gray-600"},[_vm._v("Place of work")]),_c('AppInput',{staticClass:"w-full",attrs:{"type":"text","errors":errors},model:{value:(_vm.values.company_name),callback:function ($$v) {_vm.$set(_vm.values, "company_name", $$v)},expression:"values.company_name"}})]}}],null,false,4175993630)}),_c('ValidationProvider',{attrs:{"name":"work_sector","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label-color flex mb-2 text-gray-600"},[_vm._v("Work sector")]),_c('AppSelect',{staticClass:"w-full",attrs:{"errors":errors},model:{value:(_vm.values.work_sector),callback:function ($$v) {_vm.$set(_vm.values, "work_sector", $$v)},expression:"values.work_sector"}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v("Select work sector")]),_vm._l((_vm.sectors),function(sector){return _c('option',{key:sector.value,domProps:{"value":sector.value}},[_vm._v(" "+_vm._s(sector.text)+" ")])})],2)]}}],null,false,689361505)}),_c('ValidationProvider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label-color flex mb-2 text-gray-600"},[_vm._v("Company address")]),_c('AppTextArea',{attrs:{"errors":errors},model:{value:(_vm.values.address),callback:function ($$v) {_vm.$set(_vm.values, "address", $$v)},expression:"values.address"}})]}}],null,false,3525926707)}),_c('ValidationProvider',{attrs:{"name":"work_state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label-color flex mb-2 text-gray-600"},[_vm._v("Work state")]),_c('AppSelect',{attrs:{"errors":errors},model:{value:(_vm.values.work_state),callback:function ($$v) {_vm.$set(_vm.values, "work_state", $$v)},expression:"values.work_state"}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Select work state")]),(!_vm.states.length)?_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Loading")]):_vm._e(),_vm._l((_vm.states),function(state){return _c('option',{key:state.value,domProps:{"value":state.value}},[_vm._v(" "+_vm._s(state.text)+" ")])})],2)]}}],null,false,2302419285)}),(!_vm.no_work_email)?_c('ValidationProvider',{attrs:{"name":"official_email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label-color flex mb-2 text-gray-600"},[_vm._v("Official email")]),_c('AppInput',{attrs:{"type":"email","errors":errors},model:{value:(_vm.values.official_email),callback:function ($$v) {_vm.$set(_vm.values, "official_email", $$v)},expression:"values.official_email"}})]}}],null,false,3662435605)}):_vm._e(),_c('AppCheckbox',{staticClass:"mt-4",model:{value:(_vm.no_work_email),callback:function ($$v) {_vm.no_work_email=$$v},expression:"no_work_email"}},[_vm._v("I don't have access to my work email")])]:_vm._e(),(_vm.values.occupation_id === '2' || _vm.values.occupation_id === '4' || _vm.values.occupation_id === '5')?[_c('ValidationProvider',{attrs:{"name":"Business name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label-color flex mb-2 text-gray-600"},[_vm._v("Business name")]),_c('AppInput',{staticClass:"w-full",attrs:{"type":"text","errors":errors},model:{value:(_vm.values.company_name),callback:function ($$v) {_vm.$set(_vm.values, "company_name", $$v)},expression:"values.company_name"}})]}}],null,false,2966231276)}),_c('ValidationProvider',{attrs:{"name":"Business sector","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label-color flex mb-2 text-gray-600"},[_vm._v("Business sector")]),_c('AppSelect',{staticClass:"w-full",attrs:{"errors":errors},model:{value:(_vm.values.work_sector),callback:function ($$v) {_vm.$set(_vm.values, "work_sector", $$v)},expression:"values.work_sector"}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Select business sector")]),_vm._l((_vm.sectors),function(sector){return _c('option',{key:sector.value,domProps:{"value":sector.value}},[_vm._v(" "+_vm._s(sector.text)+" ")])})],2)]}}],null,false,3262629091)}),_c('ValidationProvider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label-color flex mb-2 text-gray-600"},[_vm._v("Business address")]),_c('AppTextArea',{attrs:{"errors":errors},model:{value:(_vm.values.address),callback:function ($$v) {_vm.$set(_vm.values, "address", $$v)},expression:"values.address"}})]}}],null,false,877849330)}),_c('ValidationProvider',{attrs:{"name":"directors","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label-color flex mb-2 text-gray-600"},[_vm._v("No. of directors")]),_c('AppInput',{attrs:{"type":"number","errors":errors},model:{value:(_vm.values.directors),callback:function ($$v) {_vm.$set(_vm.values, "directors", $$v)},expression:"values.directors"}})]}}],null,false,473059555)})]:_vm._e()],2),_c('div',{staticClass:"flex mt-10"},[_c('AppButton',{on:{"click":function($event){return _vm.submit()}}},[_vm._v("Next")])],1)],1)],1),(_vm.modal === 'no-work-email')?_c('Modal',[_c('div',{staticClass:"h-full flex flex-col justify-center items-center text-center"},[_c('i',{staticClass:"fa fa-exclamation-circle text-red-500 fa-4x"}),_c('h3',{staticClass:"text-2xl mt-8 font-medium"},[_vm._v("Unable to verify work email")]),_c('p',{staticClass:"text-base mt-4"},[_vm._v(" Fulfilment will take up to 48 hours if work email is not validated. if you wish to pick up item immediately, check the checkbox below. ")]),_c('AppCheckbox',{staticClass:"mt-4",model:{value:(_vm.instant_fulfilment),callback:function ($$v) {_vm.instant_fulfilment=$$v},expression:"instant_fulfilment"}},[_vm._v("Enable instant fulfilment")]),_c('div',{staticClass:"flex items-center mt-10 space-x-4"},[_c('AppButton',{attrs:{"variant":"outline","size":"sm"},on:{"click":function($event){_vm.no_work_email = false; _vm.modal = ''}}},[_c('i',{staticClass:"fa fa-arrow-left mr-4"}),_vm._v(" Add work email ")]),_c('AppButton',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.next()}}},[_vm._v("Continue")])],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }