<template>
  <div class="h-full flex flex-col md:h-auto md:my-auto">
    <div class="px-6 pt-8 lg:pt-10 lg:px-12">
      <SectionHeader v-if="$store.state.stage === 'payment'" title="Make payment" class="mt-20 md:mt-0"/>
      <SectionHeader v-else-if="$store.state.stage === 'card'" title="Setup your debit card" class="mt-20 md:mt-0"/>
      <SectionHeader v-else :title="`Happy to fund you`" class="mt-20 md:mt-0"/>
      <AppCheckbox class="-mt-8 md:-mt-4" v-model="accepted">
        I agree to credit terms and conditions
      </AppCheckbox>
      <div class="flex mt-6">
        <AppButton :disabled="!accepted" @click="$emit('done')">
          <span v-if="!$store.state.stage">Get funded</span>
          <span v-else-if="$store.state.stage === 'offers'">View offers</span>
          <span v-else-if="$store.state.stage === 'payment'">Pay upfront</span>
          <span v-else-if="$store.state.stage === 'signature'">Setup mandate</span>
          <span v-else>Continue funding</span>
        </AppButton>
        <button
          v-if="inIframe" @click="cancel()"
          class="py-1 px-3 border border-red-700 rounded-lg text-red-700 text-sm ml-2"
        >
          Cancel
        </button>
      </div>
    </div>
    <div class="flex justify-end mt-auto md:hidden">
      <img src="../../assets/images/happy.jpeg" alt="" class="w-60"/>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/global/Button";
import SectionHeader from "@/components/global/SectionHeader";
import AppCheckbox from "@/components/global/AppCheckbox";

export default {
  name: "Preload",
  components: { AppCheckbox, SectionHeader, AppButton },
  data() {
    return {
      accepted: false,
      lender: this.$store.state.lender,
      inIframe: false
    };
  },
  created() {
    this.inIframe = window.self !== window.top;
  },
  methods: {
    cancel() {
      this.$bus.$emit('cancel');
    },
  }
}
</script>
