<template>
  <div :class="`mb-${mb}`">
    <h2 class="text-3xl lg:text-3xl font-medium w-11/12" style="color: #0a3167">{{ title }}</h2>
    <p v-if="subtitle" :class="`text-gray-500 mt-${spacing} max-w-lg`">{{ subtitle }}</p>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
    spacing: {
      type: String,
      default: '2'
    },
    mb: {
      type: String,
      default: '10'
    }
  }
}
</script>
